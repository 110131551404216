export const FILENAME_SUMMARY_NOTE = "summary-note";
export const FILENAME_FINANCING_PLAN = "financing-plan";

export enum FileStatus {
  InReview = "In Review",
  Validated = "Validated",
  Invalidated = "Invalidated",
  Empty = "Empty",
}

export enum FileReviewBackoffice {
  FinancingPlan = "Plan de financement",
  Note = "Note de synthèse",
}

export enum REFolderStatus {
  AddingInformations = "Ajout d'informations",
  Questions = "Questions",
  Completion = "Complétion des documents",
  SignatureRequired = "Signature requise",
  Analysing = "En cours d'analyse",
  DocumentVerification = "Vérification des documents",
  Auction = "Disponible pour mise aux enchères",
  Auctioned = "Mis aux enchères",
  WaitingForBankAppointment = "En attente RDV banque",
  WaitingForOfferSignature = "En attente signature offre",
  WaitingForNotaryAppointment = "En attente notaire",
  Done = "Terminé",
}

export interface FolderDetails {
  userId: string;
  propertyPrice: number;
  renovationBudget: number;
  address: string;
  notaryFeesPercentage: number;
  loanDuration: number;
  desiredContribution: number;
  status: REFolderStatus;
  files: FileData[];
  created_at: string;
  updated_at: string;
  summaryNoteFile: string;
  scoring?: number;
  financingPlanFile: string;
  id: string;
  bidInfos?: BidInfo[];
  propertyType?: PropertyType;
  condition?: Condition;
  investmentType?: InvestmentType;
  purchaseIn?: PurchaseIn;
  situation?: Situation;
  currentResidentialSituation?: CurrentResidentialSituation;
  numPeople?: number;
  hasChildren?: boolean;
  isEmployed?: boolean;
  workerNonEmploye?: boolean;
  hasCAF?: boolean;
  hasOngoingLoans?: boolean;
}

export interface Justificative {
  name: string;
  title: string;
  description: string;
  validityState: FileStatus;
  url?: string;
}

export interface FileData {
  name: string;
  status: FileStatus;
  url: string;
  created_at?: string;
  updated_at?: string;
  id: string;
  uid?: string;
  comment?: string;
}

export enum BidStatus {
  WaitingForOffer = "Waiting for offer",
  WaitingForClientFeedback = "Waiting for client feedback",
  BidLost = "Bid lost",
  BidWon = "Bid won",
}

export interface BidProposition {
  id?: string;
  amount: number;
  rate: number;
  durationInYear: number;
  contribution: number;
  domiciliationOfIncomes: boolean;
  domiciliationOfSavings: boolean;
  homeInsurance: boolean;
  otherConditions?: string;
}

export interface BidInfo {
  some(arg0: (bidInfo: BidInfo) => boolean): unknown;
  id?: string;
  bankUserId: string;
  firstEmailSent: boolean;
  active: boolean;
  status: BidStatus;
  historyStatuses: HistoryStatuses[];
  bidPropositions: BidProposition[];
}

export interface HistoryStatuses {
  status: BidStatus;
  created_at: string;
  updated_at: string;
  id: string;
}

export enum PropertyType {
  Appartment = "Appartment",
  House = "House",
  RenovationWork = "Renovation Work",
  Other = "Other",
}

export enum InvestmentType {
  RentalProperty = "Rental property",
  MainResidence = "Principal property",
}

export enum Condition {
  Old = "Old",
  New = "New",
}

export enum PurchaseIn {
  Solo = "Solo",
  WithSeveral = "With Several",
  InSCI = "In SCI",
}

export enum Situation {
  Married = "Married",
  Divorced = "Divorced",
  Single = "Single",
}

export enum CurrentResidentialSituation {
  Renter = "Renter",
  Owner = "Owner",
}
